<template>
  <van-tabbar route active-color="#019af3">
    <van-tabbar-item icon="wap-home" to="/icps/">首页</van-tabbar-item>
    <van-tabbar-item icon="graphic" to="/icps/dev">设备</van-tabbar-item>
    <van-tabbar-item icon="graphic" to="/icps/warn">告警</van-tabbar-item>
    <van-tabbar-item icon="setting" to="/icps/about">关于</van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'tabber',
}
</script>
