<template>
    <div class="location">
        <transition name="fade">
            <div key="index" class="search-btn" @click="iconShow = false" v-if="iconShow"><van-icon name="search" /></div>
            <div class="search-box" v-else>
                <van-search v-model="key" @input="watchKey" placeholder="请输入关键词检索设备" />
                <div v-if="showResult" :class="valueData.length >0 ? 'box-inner global-shadow' :'box-inner'">
                    <van-cell v-for="(item,index) in valueData" :key="index" :class="item.devStatus !== 'offline'? 'online' : 'offline'" :title="item.devName" @click="selectDev(item)">
                        <span :class="item.devStatus !== 'offline'? 'online' : 'offline'" v-text="item.devStatus !== 'offline'? '在线' : '离线'"></span>
                    </van-cell>
                </div>
            </div>
        </transition>
        <div id="container"></div> 
        <tabBar />
    </div>
</template>

<script>
import tabBar from '@/components/icps/tabBar.vue'
import iconMark from '@/assets/icps/img/dev.png'
export default {
    name: 'Home',
    components:{
        tabBar
    },
    data(){
        return{
            iconShow:true,
            showResult:false,
            allDev:[],
            valueData:[],
            key:'',
            map:'',//声明地图实例
        }
    },
    mounted(){
        const projectCode = window.sessionStorage.getItem('projectCode');
        let tabSize = document.getElementsByClassName('van-tabbar')[0].scrollHeight; // 获取tab栏的高度
        document.getElementById('container').style.height = (window.screen.height - tabSize )+'px';
        this.map = new AMap.Map('container',{
            zooms:[4,19], //设置缩放级别范围 4-19 级
        });
        AMap.plugin(['AMap.ToolBar','AMap.Scale'],() => {//异步同时加载多个插件
            var toolbar = new AMap.ToolBar();
            var scale = new AMap.Scale();
            this.map.addControl(toolbar);
            this.map.addControl(scale);
        });
        this.$api.ICPS.devList({
            projectCode:projectCode
        }).then((data) => {
            data.forEach( e => {
                const gps = [e.lon,e.lat]
                if(e.lon !== 0 || e.lat !== 0){
                    this.gpsTransformGaoDe(gps).then( d => {
                        e.lon = d.lng;
                        e.lat = d.lat;
                    })
                }
            })
            
            // 异步绘制-防止高德异步函数导致的加载失败问题
            setTimeout(()=>{
                this.allDev = data;
                this.updateMapMarker(data);
            },1500)
        })
        this.map.setZoom(4); 
        //点击地图，隐藏关闭搜索框
        this.map.on('click',() => {
            this.showResult = false;
            this.key = '';
            this.valueData = [];
            this.iconShow = true;
        })
    },
    destroyed(){
        this.map.destroy();   //销毁地图实例，防止跳转路由导致的bug报错提醒
    },
    methods:{
        // gps 转 高德坐标系
        gpsTransformGaoDe(gps){
            return new Promise( resolve => {
                new AMap.convertFrom(gps, 'gps', (status, result) => {
                    if (result.info === 'ok') {
                        resolve(result.locations[0])
                    }
                })
            })
        },
        updateMapMarker(data){
            let markArr = [];
            // ------------------------普通点标记-----------------------
            var devIcon = new AMap.Icon({
                size: new AMap.Size(22, 35),
                image: iconMark,
                imageSize: new AMap.Size(22, 35),
                imageOffset: new AMap.Pixel(0, 0)
            });
            for(let i=0;i<data.length;i++){
                if(data[i].lon){
                    var marker = new AMap.Marker({
                        position: [data[i].lon,data[i].lat],
                        offset: new AMap.Pixel(-10, -10),
                        icon: devIcon, // 添加 Icon 图标 URL 
                        label:{
                            offset: new AMap.Pixel(0, -2),  //设置文本标注偏移量
                            content: data[i].devStatus !== 'offline' ? "<span class='online'>"+data[i].devName+"</span>" : "<span class='offline'>"+data[i].devName+"</span>" , //设置文本标注内容
                            direction: 'top' //设置文本标注方位
                        }
                    });
                    marker.on('click',(e) => {
                        if(localStorage.getItem('visitor') == '1'){
                            this.$toast('暂无权限进入设备操控面板');
                            return false;
                        }
                        this.$router.push({path:'/icps/dev/detail',query:{devCode:data[i].devCode}})
                    });
                    markArr.push(marker);
                }
            }
            this.map.add(markArr);
        },
        // 监听搜索结果
        watchKey(e){
            this.valueData = this.allDev.filter(v => v.devName.toLowerCase().includes(e.toLowerCase()))   //不区分大小写
            this.valueData ? this.showResult = true : this.showResult = false;
        },
        // 点击搜索结果，视野以该设备为中心点放大视野
        selectDev(e){
            this.showResult = false;
            this.key = '';
            this.valueData = [];
            this.iconShow = true;
            let position = [e.lon, e.lat]; 
            this.map.setCenter(position); 
            this.map.setZoom(16); 
        }
    }
}
</script>
<style lang="scss">
#container {
    width:100%; height: 580px;
    .amap-marker-label{color: #443535;border: 1px solid #d2d2d2 !important;}
    .amap-copyright{display: none !important;}
}
.location{
    .online{color:#019af3 !important;font-weight: bold;}
    .offline{color: #717171 !important;font-weight: bold;}
    .search-btn{
        position: absolute;top: 2%;right: 2%;z-index: 999;
        .van-icon{font-size: 1.5rem;color: #019af3;font-weight: bold}
        }
    .search-box{
        position: absolute;top: 0;left: 0;z-index: 999;width: 100%;
        .box-inner{height: 14rem;overflow-y: scroll;}
    }
    .fade-leave-active,.fade-enter-active {
        transition: all .5s ease;
    }
    .fade-enter,.fade-leave-to{
        transform:translate3d(50%, 0, 0);
        opacity: 0;
    }
}
</style>
